import logo from "./logo.svg";
import logoWhite from "./logo_white.svg";
import heroImg from "./hero_img.svg";
import { ImLinkedin } from "react-icons/im";

import "./App.css";

function App() {
  return (
    <div className="h-screen flex flex-col">
      <header className="flex bg-white border-b border-gray-300 px-4 lg:px-6 py-2.5">
        <nav className="flex w-full justify-between">
          <a href="/" className="flex items-center">
            <img src={logo} className="mr-3 h-6 sm:h-9" alt="JPrime Logo" />
          </a>
          <div className="flex items-center gap-2">
            <a
              href="https://www.linkedin.com/showcase/jtalent-consultancy"
              target="_blank"
              rel="noreferrer"
            >
              <ImLinkedin className="w-8 h-8 text-[#0077B5]" />
            </a>
            <a
              href="https://wa.me/916379671001"
              target="_blank"
              className="px-4 py-2 rounded-full bg-[#db0106] text-white hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] transition duration-200"
              rel="noreferrer"
            >
              Contact
            </a>
          </div>
        </nav>
      </header>
      <main className="flex flex-col flex-1">
        <section className="w-full">
          <div className="container mx-auto px-4 py-12">
            <div className="grid lg:grid-cols-2 gap-4 items-center">
              <div className="space-y-6 items-center">
                <div>
                  <h1 className="text-5xl">Find your next SaaS Champion</h1>
                  <span className="w-16 border-b border-[#db0106] h-1 inline-block"></span>
                </div>
                <p className="text-2xl text-slate-600">
                  JTalent is a SaaS recruitment consultancy, connecting
                  organizations with the talents needed to drive their goals.
                </p>
                <div>
                  <a
                    href="https://wa.me/916379671001"
                    target="_blank"
                    className="inline-block px-6 py-4 rounded-full bg-[#db0106] text-white hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] transition duration-200"
                    rel="noreferrer"
                  >
                    Find Saas Talent
                  </a>
                </div>
              </div>
              <div className="">
                <img src={heroImg} alt="Saas Talents" />
              </div>
            </div>
          </div>
        </section>
        <section className="w-full">
          <footer className="flex flex-col bg-slate-950 items-center p-4 justify-center space-y-4">
            <img src={logoWhite} className="h-6" alt="JPrime Logo" />
            <p className="text-white text-sm text-center">
              &copy; {new Date().getFullYear()}, Service provided by{" "}
              <a href="http://jprime.in" className="text-yellow-300">
                JPrime Enterprises (OPC) Pvt. Ltd.
              </a>{" "}
              All Rights Reserved.
            </p>
          </footer>
        </section>
      </main>
    </div>
  );
}

export default App;
